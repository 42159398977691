.loader {
	width: 48px;
	height: 48px;
	display: inline-block;
	position: relative;
}
.loader::after,
.loader::before {
	content: "";
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: black;
	position: absolute;
	left: -14px;
	top: -14px;
	box-sizing: border-box;
	animation: animloader 2s ease-in-out infinite;
}

.spin {
	:global(.ant-spin-nested-loading) {
		height: 100% !important;
	}
}

@keyframes animloader {
	0%,
	100% {
		transform: scale(0);
		opacity: 1;
	}
	50% {
		transform: scale(1);
		opacity: 0;
	}
}
