@import 'sass/colors';
@import 'sass/mixins';

.blockUrl {
  display: flex;
  input {
    width: 400px;
    margin-left: 5px;
  }
}

.control {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
