@import "../styles/variables.scss";

.btn {
	background-color: $black;
	font-family: $font;
	box-shadow: none;
	height: 40px;
	min-width: 40px;
	color: $white;
	transition: none !important; /* Убирает все переходы */
	animation: none !important; /* Убирает анимации */
	svg {
		color: $white;
	}
	&:hover {
		box-shadow: none;
		border-color: $black !important;

		svg {
			color: $black;
		}
	}

	&:global(.ant-btn-link) {
		&:global(.ant-btn-dangerous) {
			color: $red;
		}
		cursor: pointer;
		border: 0;
		background: none;
		&:hover {
			color: $grey !important;
		}
	}
}
