@import "../../styles/variables.scss";

.bar {
	color: white;
	font-size: 12px;
	padding: 12px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	word-break: break-word;
	display: flex;
	flex-direction: column;
	font-size: 14px;
}

.animate {
	transition: all ease-in-out 0.5s;
}

.number {
	font-weight: 500;
}

.name {
	opacity: 0.5;
}
