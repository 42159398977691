@import "../styles/variables.scss";

.container {
}

.header {
	padding: 10px;
	border-bottom: 8px solid rgba(0, 0, 0, 0.08);
}
.item {
	padding: 4px;
	width: 360px;
	.gridItem {
		cursor: pointer;
		background-color: $white;
		border-radius: 8px;
		border: 1px solid #d5d5d5;
		height: 100%;
		overflow: hidden;
	}
}

.buttonIcon {
	padding: 0;
	border: 0;
	background-color: transparent;
	cursor: pointer;
	svg {
		color: $black;
	}
	&:hover {
		border: 0;
		background: transparent;
		background-color: transparent !important;
	}
}

.gridItemContent {
	position: relative;
	width: 100%;
	height: 100%;
}

.widgetSettings {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	z-index: 999;
	height: 50px;
	transition: all 0.2s ease;
	.buttonIcon {
		align-self: flex-end;
		background-color: transparent;
		svg {
			color: $black;
		}
	}
}

.popover {
}

.menuCard {
	display: none;
	flex-direction: column;

	.menuItem {
		border-bottom: 1px solid #3e3e3e;
		border-radius: 0;
		&:last-child {
			border: none;
		}
	}
}

.widgetSettings.expanded {
	background-color: $dark;
	height: 100%; /* Раскрытие на всю высоту карточки */

	.menuCard {
		display: flex;
	}
	.buttonIcon {
		svg {
			color: $white;
		}
	}
}

.widgetSettings {
	position: absolute;
	top: 0;
	right: 0;
}

.dropzone {
	flex: 1;
	min-height: 730px;
	margin-left: -4px;
	border-radius: 1rem;
	display: "flex";
	flex-wrap: wrap;
	gap: 16px;
	overflow: hidden;
	overflow-y: scroll;
	border: 1px dashed transparent;
}

.activeDropZone {
	border: 1px dashed rgba(0, 0, 0, 0.1);
	background-color: rgba(0, 0, 0, 0.02);
}

.reaction {
	padding: 24px;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
}
