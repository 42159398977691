.react-datepicker {
	width: max-content;
}

body {
	overflow-x: hidden;
	margin: 0;
}

html {
	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 6px;
		background-color: #188fff9a;
	}
}
