@import "../styles/variables.scss";

.avatar {
	min-width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 10px;
	border: 1px solid transparent;
	transition: all 0.5s ease-in-out;
}

.overlay {
	width: 100%;
}

.menuList {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		padding: 16px 15px;
	}
}

.header {
	padding: 16px 15px;
	border-bottom: 8px solid rgba(0, 0, 0, 0.08);
	display: none;
	@media (max-width: 1024px) {
		display: block;
	}
}

.container {
	display: flex;
	cursor: pointer;
	border-radius: 4px;
	max-width: 160px;
}

:global(.ant-dropdown-open) {
	.avatar {
		border: 1px solid $white;
	}
}

.wrapText {
	white-space: nowrap; /* Не переносить текст */
	overflow: hidden; /* Скрыть текст, выходящий за пределы */
	text-overflow: ellipsis;
}

.name {
	@extend .wrapText;
}
.role {
	color: rgba(255, 255, 255, 0.5);
	@extend .wrapText;
}

.dividerItem {
	display: flex;
	height: 1px;
	width: 100%;
	background-color: #3e3e3e;
}

.wrapper {
	cursor: pointer;
	@media (max-width: 1024px) {
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		.avatar {
			margin-right: 0;
		}
		.info {
			display: none;
		}
	}
}
.info {
	text-align: left;
	overflow: hidden;
}
