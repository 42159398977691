@import "../../styles/variables.scss";

.container {
	display: flex;
	min-height: 100%;
	background: $dark;
	color: $white;
	align-items: center;
	justify-content: center;
	font-family: $font;
}

.content {
	display: flex;
	align-items: center;
	flex-direction: column;
	.header {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.img {
		svg {
			width: 40px;
			height: 40px;
		}
	}
}

.widgetLoading {
	:global(.ant-spin-container) {
		height: 100%;
	}
}
