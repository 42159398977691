@import "../styles/variables.scss";

$md_small_size: 570px;

.toggleContainer {
	display: flex;
	align-items: center;
	background: white;
	border: 1px solid #d1d1d1;

	border-radius: 8px;
	padding: 3px;
	position: relative;
	width: 462px;
	justify-content: space-between;
}

.toggleButton {
	position: relative;
	z-index: 2;
	padding: 10px 16px;
	font-size: 14px;
	line-height: 12px;
	font-weight: 500;
	color: $black;
	background: transparent;
	border: none;
	cursor: pointer;
	transition: color 0.3s ease;
	font-family: $font;
}

.active {
	color: $white;
}

.toggleHighlight {
	position: absolute;
	top: 4px;
	bottom: 4px;
	left: 4px;
	background: $black;
	border-radius: 8px;
	transition: transform 0.3s ease;
}

.mobileToggle {
	display: none;
}

@media (max-width: $md_small_size) {
	.toggleContainer {
		display: none;
	}
	.mobileToggle {
		display: block;
	}
}
