.wrapper {
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}
.loginContainer {
  display: flex;
  justify-content: center;
  // margin-top: 100px;
  align-items: center;
  flex-direction: column;
}

.logo {
  font-family: 'system-ui', sans-serif;
  cursor: pointer;
  color: #001529;
  margin-bottom: 40px;
  font-weight: bold;
  position: relative;
}

.logo::before {
  content: attr(data-text);
  position: absolute;
  z-index: 1;
  color: #0ea5e9;
  pointer-events: none;
  width: 0;
  overflow: hidden;

  transition: 0.4s ease-out width;
}

.logo:hover::before {
  width: 100%;
}

.form {
  width: 400px;
  max-height: 300px;
}

.lastBlock {
  :global(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: center;
  }
}

.block {
  display: flex;
  justify-content: center;
}

.verison {
  font-family: 'system-ui', sans-serif;
  position: fixed;
  bottom: 10px;
  right: 20px;
  color: #ddd;
}

.message {
  color: #1e77e1;
  font-weight: bold;
  margin-bottom: 0;
}

.email {
  color: #001529;

  text-align: center;
  p {
    font-weight: bold;
    font-size: 32px;
  }
}
