.formContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.selector {
  :global(.ant-select-selector) {
    padding: 0 7px !important;
  }

  :global(.ant-select-selection-item) {
    line-height: 35px !important;
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
}

.container {
  display: flex;
  justify-content: space-between;
}
