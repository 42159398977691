@import "../styles/variables.scss";

.dropdown {
	// background-color: red;
	background-color: $dark !important;
	border-radius: 8px !important;
	:global(.ant-select-item-option-selected) {
		color: rgba(255, 255, 255, 0.5) !important;
		background: none !important;
	}

	:global(.ant-empty) {
		padding: 20px 0;
		:global(.ant-empty-description) {
			color: $white;
		}
	}

	:global(.ant-select-item) {
		font-family: $font;
		color: $white;
		border-bottom: 1px solid #3e3e3e;
		border-radius: 0;

		padding: 11px 16px !important;
		&:last-child {
			border: none;
		}
	}
	:global(.anticon-check) {
		color: $white;
	}
}

.select {
	:global(.ant-select-selection-item) {
		// background: $white;
		// color: $dark !important;
		border-radius: 8px;
		font-family: $font;
	}
	&:global(.ant-select-open) {
		:global(.ant-select-selector) {
			background-color: $dark;
			:global(.ant-select-selection-item) {
				color: $white;
				font-family: $font;
			}
		}

		:global(.ant-select-selection-item-remove) {
			svg {
				color: $white;
			}
		}

		:global(.ant-select-selection-search-input) {
			color: $white !important;
		}

		:global(.ant-select-selection-placeholder) {
			color: $grey !important;
		}

		:global(.ant-select-arrow) {
			color: $white;
		}
	}

	&:hover {
		border-color: $grey !important;
	}
	:global(.ant-select-dropdown) {
		background-color: $dark;
	}
	:global(.ant-select-selector) {
		display: flex;
		align-items: center;
		min-width: 100px;
		border-radius: 8px;
		height: auto !important;
		min-height: 40px !important;
		box-shadow: none !important;
		border-color: $grey !important;

		&:hover {
			border-color: $grey !important;
		}
	}
	&:after {
		color: $black;
	}

	:global(.ant-select-arrow) {
		color: $black;
	}
}
