.inputMask {
  height: 22px;
  width: 180px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 2px;
  border-radius: 6px;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    border-width: 1px;
    border-color: #1890ff;
  }
  &:focus-visible {
    transition: all 0.3s;
    outline: none;
    border: solid 1px #2d99ff;
    box-shadow: 0 0px 1px 2px #d5ebff;
  }
}

.modal {
  position: absolute;
  top: 32px;
  left: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px;
  background-color: #fff;
  z-index: 500;
  color: black;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  transition: all 0.2s linear;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.client {
  cursor: pointer;
  padding: 4px;
  &:hover {
    background-color: #f5f5f5;
  }
}

.photo {
  :global(.ant-list-item-meta-avatar) {
    margin-right: 10px;
  }
  :global(.ant-list-item-meta-title) {
    margin: 0;
  }
}
