.rowP {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
}

.firstRow {
  font-weight: 500;
}

.inputMask {
  height: 31px;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    border-width: 1px;
    border-color: #1890ff;
  }
  &:focus-visible {
    transition: all 0.3s;
    outline: none;
    border: solid 1px #2d99ff;
    box-shadow: 0 0px 1px 2px #d5ebff;
  }
}

.smallP {
  font-size: 12px;
}

.datePicker {
  height: 31px;
  max-width: 400px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    border-width: 1px;
    border-color: #1890ff;
  }
  &:focus-visible {
    transition: all 0.3s;
    outline: none;
    border: solid 1px #2d99ff;
    box-shadow: 0 0px 1px 2px #d5ebff;
  }
}

.formContainer {
  padding: 35px 15px;
  max-width: 1200px;
  margin: 0 auto;

  :global(.editor-inner) {
    height: 100%;
    max-height: 100%;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 24px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  justify-content: space-between;
}

.logoSvg {
  width: 200px;
  max-width: 100%;
  height: 64px;
  border-right: 1px solid rgba(61, 87, 170, 0.15);
  padding-right: 24px;
}

.bold {
  font-weight: 500;
}

.number {
  height: 64px;
  border-left: 1px solid rgba(61, 87, 170, 0.15);
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.numberLabel {
  margin-right: 5px;
}

.notif {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 700px) {
  .header {
    flex-direction: column;
  }
  .logoSvg {
    border-right: 0;
    padding-right: 0;
  }
  .number {
    border-left: 0;
    padding-left: 0;
    flex-direction: column;
  }
}
