@import "../styles/variables.scss";

$lg_menu_size: 1024px;
$md_menu_size: 570px;
$mobile_menu_size: 360px;

:global(.ant-menu-vertical.ant-menu-sub) {
	background-color: $dark !important;
	color: $white;
}

:global(.ant-tour-indicator-active) {
	background-color: $orange !important;
}

:global(.ant-menu-overflow-item) {
	@media (max-width: $lg_menu_size) {
		margin: 0 3px !important;
		// &:last-child {
		// 	margin-right: 0;
		// }
	}

	@media (max-width: $md_menu_size) {
		margin: 0 2px !important;
	}
}

:global(.ant-menu-title-content) {
	margin-inline-start: 0 !important;
	font-size: 16px;
	a {
		font-family: $font;
		font-size: 16px;
	}
}

:global(.ant-menu-sub) {
	.dividerItem {
		display: flex;
		height: 1px;
		width: 100%;
		background-color: #3e3e3e;
	}
}

.buttonExit {
	color: $red;
}

:global(.ant-menu-item) {
	:global(.ant-menu-item-icon) {
		display: none;
	}
}

.menuItem {
	:global(.ant-menu-item-icon) {
		display: none !important;
	}
}

.char {
	width: 100%;
	height: 100%;
}

:global(.ant-tour) {
	font-family: $font;

	:global(.ant-tour-inner) {
		background-color: $dark;
		color: $white;
		:global(.ant-tour-close) {
			color: $white;
			cursor: pointer;
			&:hover {
				color: $white;
			}
		}
	}
}

.nextButton {
	padding: 11px 20px !important;
	height: auto !important;
	line-height: initial;
	color: $dark;
	font-family: $font;
	background-color: $white;
	box-shadow: none;
	border: none !important;
	&:hover {
		color: $white !important;
		background-color: $black !important;
	}
}

.submenuTitle {
	font-size: 17px;
	color: $grey;
	height: 40px;
	line-height: 40px;
	padding-inline: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-block: 4px;
	border-bottom: 8px solid $black-8;
	font-family: $font;
}

.hoverItem {
	background: $dark;
	border-radius: 16px;
}

:global(.ant-menu-submenu .ant-menu-item-active) {
	color: rgba(255, 255, 255, 0.5) !important;
}
:global(.ant-menu-submenu .ant-menu-item-selected) {
	background: transparent !important;
	color: rgba(255, 255, 255, 0.5) !important;
}

.panel {
	// position: sticky;
	bottom: 10px;
	height: 80px;
	width: calc(100% - 40px);
	z-index: 1000;

	position: fixed;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	max-width: $menu-width;
	margin: 0 auto;
	background: $black;
	text-align: center;
	padding: 24px 30px;
	border-radius: 16px;
	color: $white;
	display: flex;
	align-items: center;
	font-family: $font;

	@media (max-width: $lg_menu_size) {
		padding: 8px;
	}

	.logo {
		font-size: 24px;
		flex: 0;
		padding-right: 24px;
		width: 100%;
	}

	.menuContainer {
		display: flex;
		flex-direction: row;
		font-size: 16px;
		flex: 1;
		overflow-y: hidden;
		padding: 0 24px;
	}

	.menu {
		width: 100%;
		font-family: $font;
		background: transparent;
		color: $white;

		:global(.ant-menu-item-selected) {
			color: $white;
			@extend .hoverItem;

			::after {
				display: none;
			}
		}

		:global(.ant-menu-submenu-selected) {
			color: $white;
			@extend .hoverItem;
			:global(.ant-menu-submenu-title) {
				color: $white;
			}
		}

		:global(.ant-menu-item-selected:after) {
			display: none;
		}

		:global(.ant-menu-overflow-item:hover) {
			@extend .hoverItem;
		}
		:global(.ant-menu-overflow-item::after) {
			display: none;
		}

		:global(.ant-menu-item-active::after) {
			display: none;
		}

		:globlal(.ant-menu-overflow-item) {
			display: none;
		}

		:global(.ant-menu-item-active:hover) {
			color: $white !important;
			@extend .hoverItem;
		}

		:global(.ant-menu-submenu) {
			padding-inline: 0;
			display: flex;
			border-radius: 16px !important;
		}

		:global(.ant-menu-overflow-item-rest) {
			:global(.ant-menu-item-icon) {
				display: none;
				svg {
					font-size: 24px;
				}
			}
		}

		:global(.ant-menu-submenu-title) {
			padding-inline: 16px;
		}

		:global(.ant-menu-item) {
			border-radius: 16px !important;
			margin-inline: 1px;
		}

		:global(.ant-menu-item-icon) {
			display: none;
		}
	}

	.divider {
		min-width: 1px;
		height: 100%;
		background-color: $white;
	}

	@media (max-width: $lg_menu_size) {
		max-width: 100%;
		width: 540px;
		margin: 0 auto;

		:global(.ant-menu-submenu) {
			background-color: #1b1b1b;
		}

		:global(.ant-menu-item) {
			background-color: #1b1b1b;
		}

		:global(.ant-menu-overflow-item-rest) {
			display: flex;
			align-items: center;
			:global(.ant-menu-submenu-title) {
				width: 60px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				:global(.anticon-ellipsis) {
					display: none;
				}

				&::before {
					transition: transform 0.5s ease-in-out;
					content: "+"; /* Подключение SVG файла */
					display: inline-block;
					width: 20px;
					height: 20px;
					font-size: 30px;
					line-height: 20px;
				}
			}
		}

		.divider {
			display: none;
		}

		.logo {
			display: none;
		}

		.menuContainer {
			padding: 0 30px 0 0;
		}

		:global(.ant-menu-item) {
			border-radius: 16px !important;
		}
		:global(.ant-menu-overflow-item) {
			border-radius: 16px;
		}

		:global(.ant-menu-overflow-item-rest.ant-menu-submenu-open) {
			:global(.ant-menu-submenu-title) {
				&::before {
					transform: rotate(135deg);
				}
			}
		}

		.menu {
			.menuItem {
				:global(.ant-menu-item-icon) {
					display: inline-flex !important;
				}
			}

			:global(.ant-menu-title-content) {
				text-indent: -9999px;
				white-space: nowrap; /* Не дает тексту переноситься */
				overflow: hidden; /* Убирает прокрутку */
				display: block;
				height: 0;
			}

			:global(.ant-menu-submenu-title) {
				padding-inline: 0;

				:global(.ant-menu-item-icon) {
					width: 60px;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			:global(.ant-menu-item) {
				padding-inline: 0;

				:global(.ant-menu-item-icon) {
					width: 60px;
					height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
	@media (max-width: $md_menu_size) {
		max-width: 100%;
		width: auto;
		// margin: 0 10px;
	}
}

@keyframes fadeIn {
	0% {
		width: 100%;
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		width: 100%;
		opacity: 1;
		transform: translateY(0);
	}
}

.text {
	letter-spacing: 0.05em;
	width: 100%;
	opacity: 0;
	animation: fadeIn 1s ease-in-out forwards;
}
