@import 'sass/colors';
@import 'sass/mixins';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

$list-width: 250px;

.list {
  width: $list-width;
  height: 100%;
  overflow-y: auto;
  @include scrollbar($blue);
  // Да-да...
  max-height: calc(100vh - 256px);
}

.addArticleButton {
  width: $list-width;
}

.editorWrapper {
  width: 100%;
  margin-left: 24px;
  position: relative;
  max-height: calc(100vh - 240px);
}

.editorWrapperReadOnly {
  max-height: calc(100vh - 180px);
}

.spinnerContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.search {
  margin-bottom: 18px;
}

.listSearch {
  background: #fff;
  &:hover {
    background: #fff;
  }
}

.content {
  display: flex;
  height: 100%;
}

.editorWrapperSpacer {
  width: 100%;
  height: 100%;

  :global(.ant-space-item):last-child {
    height: 100%;
  }
}

.articleTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 60vw;

  &:global(.ant-typography) {
    margin-bottom: 0;
  }
}

.mainRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.tags {
  margin-bottom: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.openArticleTags {
  margin-top: 2px;
  margin-bottom: 5px;
}
