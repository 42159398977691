@import 'sass/colors.scss';

.title {
  margin-bottom: 0 !important;
}

.merchant_card {
  display: grid;
  margin-top: 50px;
  padding: 20px 25px;
  border-radius: 10px;
  background-color: white;
  grid-template-areas: 'left right';
  grid-template-columns: 150px fit-content(80%);
  gap: 10px 15px;
  width: inherit;
  position: relative;
}

.edit_button {
  position: absolute;
  bottom: 20px;
  right: 70px;
  font-size: 30px;
  color: $blue;
  &:hover {
    cursor: pointer;
  }
}

.table {
  margin-top: 50px;
}

.icons {
  font-size: 25px;

  &:first-child {
    margin-right: 20px;
  }
}