@import "../../shared//components/styles/variables.scss";

.updateInformation {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	margin-right: 16px;
	font-family: $font;
	color: $black;

	span {
		opacity: 0.4;
	}
}

.gretting {
	font-size: 40px;
	display: flex;

	@media (max-width: 740px) {
		font-size: 40px;
	}
}

.hi {
	opacity: 0.2;
}

.name {
	overflow: hidden;
	width: 0;
	white-space: nowrap;
	animation: typing 2s steps(60) 1s 1 normal both, blink 1s step-end forwards;
}

.control {
	display: flex;
	align-items: center;
}

.header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	flex-wrap: wrap;
}
.toolbar {
	display: flex;

	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	.toolbarLeft {
		display: flex;
		flex-wrap: wrap;
	}

	.toolbarRight {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.block {
		padding: 5px 0;
		display: flex;

		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}

@keyframes typing {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes blink {
	0%,
	100% {
		border-right: 2px solid transparent;
	}
	50% {
		border-right: 2px solid black;
	}
}

.activeSidebar {
	background-color: $black !important;
	svg {
		color: $white !important;
	}
}
