@import 'sass/mixins';
@import 'sass/colors';

.title {
  margin-bottom: 0 !important;
}

.topContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .applyButton {
    margin-left: 30px;
  }
}

.currentUser {
  display: flex;
  justify-content: flex-end;
}

.leftInfo {
  display: flex;
  flex-direction: column;

  .leftInfoType {
    font-weight: 700;
    font-size: 16px;
    margin-top: 10px;
  }
}

.datePick {
  width: max-content;
}

.linkToProblem {
  margin-top: 20px;
}

.rightInfoContainer {
  margin-left: auto;

  ul {
    font-size: 16px;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.datePicker {
  height: 32px;
  margin-top: -3px;
}

.infoCard {
  position: relative;
}

.editButton {
  position: absolute;
  bottom: 25px;
  right: 40px;
  font-size: 32px;
  color: #1890ff;
  transition: 0.3s all;
  cursor: pointer;
}

.mailIcon {
  position: absolute;
  bottom: 25px;
  right: 85px;
  font-size: 32px;
  color: #1890ff;
  transition: 0.3s all;
  cursor: pointer;
}

.photoButton {
  position: absolute;
  bottom: 25px;
  right: 130px;
  font-size: 32px;
  color: #1890ff;
  transition: 0.3s all;
  cursor: pointer;
}

.metaButton {
  position: absolute;
  bottom: 27px;
  right: 169px;
  font-size: 32px;
  color: #1890ff;
  transition: 0.3s all;
  cursor: pointer;
}

.editButton {
  position: absolute;
  bottom: 27px;
  right: 40px;
  font-size: 32px;
  color: #1890ff;
  transition: 0.3s all;
  cursor: pointer;
}

.deleteButtonIcon {
  font-size: 30px;
  color: #1890ff;
  transition: 0.3s all;
  cursor: pointer;

  margin-left: 15px;
}

.answerTextarea {
  overflow-y: auto;
  resize: none;
}

.spinnerContainer {
  margin: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.spinner {
  margin-top: 30vh;

  span {
    font-size: 40px;
  }
}

.iconZoomIn {
  font-size: 22px;
}

.clientCardList {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  font-size: 16px;
}

.clientCardListItem {
  display: flex;
  justify-content: flex-start;
  color: rgb(119, 119, 119);

  p {
    margin: 0 0 0 10px;
    color: black;
    max-width: 550px;
  }
}

.formContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.decisionContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.decisionSelect {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.singleSelect {
  width: 300px;
  margin-right: 10px;
}

.sysDecisionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.commentsContainer {
  max-height: 330px;
  overflow-y: scroll;
  @include scrollbar($gray);
}

.commentCard {
  width: 500px;
}

.userAvatar {
  font-size: 29px;
}

.commentTitle {
  display: flex;
  align-items: center;

  .commentName {
    font-size: 21px;
    margin: 0;
  }

  .commentTime {
    font-size: 14px;
    margin: 5px 0 0 10px;
    color: rgb(153, 152, 152);
  }
}

.comment {
  color: black;
  font-size: 16px;
}

.problemResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.problemResultDescription {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  li {
    display: flex;
  }
}

.inputMask {
  height: 32px;
  width: 204px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    border-width: 1px;
    border-color: #1890ff;
  }

  &:focus-visible {
    transition: all 0.3s;
    outline: none;
    border: solid 1px #2d99ff;
    box-shadow: 0 0px 1px 2px #d5ebff;
  }
}

.formRow {
  justify-content: space-between;
}

.formCol {
  width: 47%;
}

.imagePreview {
  margin-right: 10px;
  position: relative;
}

.photoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.downloadRow {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;

  & .fileName {
    font-size: 18px;
  }
}

.downloadIcon {
  position: absolute;
  bottom: 2px;
  left: 135px;
  font-size: 26px;
  color: #1890ff;
  transition: 0.3s all;

  &:hover {
    cursor: pointer;
    transition: 0.3s all;
    font-size: 30px;
    left: 134px;
  }
}

.chatFolderIcon {
  position: absolute;
  color: #1890ff;
  font-size: 22px;
  top: 10%;
  right: 5%;
  cursor: pointer;
}

.timelineContainer {
  margin-top: 20px;
  max-width: 800px;
}

.userLabel {
  color: #1677ff;
}

.deleteImageButton {
  position: absolute;
  top: -8px;
  right: -6px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 1;
  background: #ff4d4f;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorDelete {
  color: #fff;
  font-size: 10px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  // margin-left: 11px;
}

.marker {
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: red;
    position: absolute;
    right: 0;
    top: -4px;
  }
}

.ticketTopPanel {
  display: flex;
  align-self: flex-start;
}

.colClientTitile {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.titleClientPhone {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
}

.clientForm {
  margin-top: 10px;
}

.row {
  cursor: pointer;
}

.pansControl {
  display: flex;
}

.tagsPanel {
  display: flex;
  flex-direction: column;
  :global(.ant-tag) {
    margin-bottom: 8px;
  }
}

.newTab {
  position: relative;
  // &::after {
  //   content: 'New';
  //   display: flex;
  //   position: absolute;
  //   font-size: 12px;
  //   color: tomato;
  //   top: -12px;
  //   right: -15px;
  // }
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
  margin-top: 20px;
}
