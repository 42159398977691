$white: #ffffff;

$black: #000000;
$black-8: rgba(0, 0, 0, 0.08);

$grey: #d5d5d5;
$dark: #333;
$dark_light: #1b1b1b;

$orange: #ff7f00;
$orange_light: #ff9100;
$violet: #6e0aeb;
$violet_light: #821eff;
$red: #ff3b30;
