.container {
  position: relative;
}

.formItem {
  margin-right: 20px;
  align-content: flex-end;
}
.checkBox {
  margin-top: 35px;
}
.rowSelector {
  margin-left: 10px;
}

.buttonContainer {
  display: flex;
}
.dropDown {
  height: 40px;
  font-size: 18px;
}

.reportArea {
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
  background: #fff;
  padding: 15px 10px;
  span {
    text-align: center;
  }
}

.dailyUserForm {
  :global(.ant-btn) {
    margin: 0 !important;
  }

  :global(.ant-form-item-control) {
    justify-content: flex-end;
  }
}

.wide {
  width: 400px !important;
}

.datapicker {
  width: 250px;
}

.reportForm {
  margin-top: 17px;
  margin-left: 5px;
}

.formItem2 {
  display: flex;

  margin-bottom: 0;
  label {
    height: 22px !important;
  }

  :global(.ant-form-item-control-input-content) {
    display: flex;
  }

  .label {
    max-width: 500px;
  }

  .buttonFormEdit {
    width: auto;
    height: 22px;
    padding: 0;
  }

  input {
    border-color: #4096ff;
    outline: 0;
    padding: 0 2px;
    height: 22px !important;
  }
}

.formTextArea {
  margin-bottom: 0;
  textarea {
    width: 500px;
    padding: 0 10px;
    min-height: 22px !important;
  }
}
