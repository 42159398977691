@import '../styles/variables.scss';

.popover {
  :global(.ant-popover-inner) {
    background-color: $dark;
    border-radius: 16px;
    padding: 0;
  }

  :global(.ant-popover-inner-content) {
    color: $white;
  }
}
