@import "../../styles/variables.scss";

.chartContainer {
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 100%;
}

.floatLeft {
	.column {
		margin-right: 8px;
	}
}
.floatCenter {
	justify-content: space-around;
}

.column {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 32px;
	min-width: 0;
	flex-shrink: 1;
}

.barStack {
	display: flex;
	flex-direction: column-reverse;
	align-items: stretch;
	// width: 32px;
	height: 100%;
	background: #ddd;
	background: rgba($dark, 0.05);

	border-radius: 32px;
	overflow: hidden;
	position: relative;

	width: 100%; /* Позволяет уменьшаться */
}

.bar {
	width: 100%;
	transition: all 0.3s ease;
	cursor: pointer;
	border-radius: 4px 4px 0 0;
	border-radius: 32px;
	position: absolute;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.number {
	margin-bottom: 12px;
	color: $white;
}

.bar:hover {
	filter: brightness(1.2);
}

.dayLabel {
	margin-top: 8px;
	font-size: 14px;
	color: #555;
	text-align: center;
}
