.wrapper {
  display: flex;
  min-height: 100vh;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}
.loginContainer {
  display: flex;
  justify-content: center;
  // margin-top: 100px;
  align-items: center;
  flex-direction: column;
}

.logo {
  color: #001529;
  margin-bottom: 50px;
  font-weight: bold;
}

.form {
  width: 400px;
  max-height: 300px;
}

.lastBlock {
  :global(.ant-form-item-control-input-content) {
    display: flex;
    justify-content: center;
  }
}

.verison {
  position: fixed;
  bottom: 10px;
  right: 20px;
  color: #ddd;
}
