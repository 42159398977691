@import "../../styles/variables.scss";
.container {
	display: flex;
	min-height: 100%;
	font-family: $font;
	flex-direction: column;
	justify-content: flex-end;
}

.bar {
	// width: 191px;
	padding: 10px 24px;
}

.header {
	padding: 18px 24px;
	font-size: 14px;

	position: fixed;
	top: 0;
	.title {
		color: hsl(0, 0%, 0%);
		// opacity: 0.4;
		font-weight: 500;
		letter-spacing: 0.02em;
		font-style: normal;
		/* Проблемные киоски */
	}
}

.count {
	font-size: 50px;
	padding: 10px 24px;
}

.widgetLoading {
	:global(.ant-spin-container) {
		height: 100%;
	}
}
