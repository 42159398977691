@import "../styles/variables.scss";

.switch {
	height: 32px;
	width: 56px;
	border: 2px solid $white;
	background: $black-8;
	:global(.ant-switch-handle) {
		width: 24px;
		height: 24px;
		top: 2px;
		border-radius: 50%;
		&:before {
			border-radius: 50%;
			background-color: $violet;
			box-shadow: none;
		}
	}

	&:global(.ant-switch-checked) {
		&:hover {
			background: $dark !important;
		}
		background: $black;
		:global(.ant-switch-handle) {
			inset-inline-start: calc(100% - 26px);
		}
	}
}
