.label {
	display: flex;
	position: relative;
	.priority {
		margin-left: 5px;
		background-color: red;
		position: absolute;
		right: 0;
		width: 20px;
		height: 20px;
		z-index: 1000;
	}
}

.item {
	float: left;
	margin-right: 5px;
	display: flex;
}

.priority {
	svg {
		color: orange;
	}
}

.selectLayout {
	:global(.ant-select-selection-item) {
		display: flex;
		align-items: center;
	}
}
