.rowP {
  margin: 0;
  font-size: 16px;
  line-height: 1.2;
}

.firstRow {
  font-weight: 500;
}

.form_container {
  padding: 10px;
}

.inputMask {
  height: 32px;
  max-width: 400px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    border-width: 1px;
    border-color: #1890ff;
  }

  &:focus-visible {
    transition: all 0.3s;
    outline: none;
    border: solid 1px #2d99ff;
    box-shadow: 0 0px 1px 2px #d5ebff;
  }
}

.tagsPanel {
  display: flex;
  flex-direction: column;
  :global(.ant-tag) {
    margin-bottom: 8px;
  }
}

.smallP {
  font-size: 12px;
}

.datePicker {
  height: 31px;
  max-width: 400px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  border-radius: 2px;
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    border-width: 1px;
    border-color: #1890ff;
  }

  &:focus-visible {
    transition: all 0.3s;
    outline: none;
    border: solid 1px #2d99ff;
    box-shadow: 0 0px 1px 2px #d5ebff;
  }
}

.kiosksProblem {
  width: 100%;
  :global(.ant-tag) {
    overflow: hidden;
  }
}

.popover {
  cursor: pointer;
}
