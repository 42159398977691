@import "../styles/variables.scss";
$lg_menu_size: 1024px;
.dropdown {
	background-color: $dark;
	border-radius: 16px;
	padding: 0;
	color: $white;
	background: transparent;

	.blockInnerMenu {
		inset: auto auto 20px 0;
		position: relative;
	}
	@media (max-width: $lg_menu_size) {
		.blockInnerMenu {
			border-radius: 16px;
			background-color: $dark;

			inset: auto auto 7px 0;
		}
	}
	:global(.ant-dropdown-menu) {
		border-radius: 16px;
		background-color: $dark;
		color: $white;
		padding: 0;
		:global(.ant-dropdown-menu-item) {
			border-bottom: 1px solid #3e3e3e;
			border-radius: 0;
			&:last-child {
				border: none;
			}
		}
	}
	:global(.ant-dropdown-menu-title-content) {
		font-family: $font;
		color: $white;
		font-size: 16px;
		transition: all 0.5s ease-in-out;

		&:hover {
			color: rgba(255, 255, 255, 0.5) !important;
		}

		a {
			display: block;
			width: 100%;
			padding: 11px 16px !important;
		}

		span {
			display: block;
			width: 100%;
			padding: 11px 16px !important;
		}
	}

	:global(.ant-dropdown-menu-item) {
		padding: 0 !important;
	}
}
