.notification {
	top: 0;
}

:global(.ant-modal-content) {
	box-shadow: none !important;
}

:global(.ant-notification-notice) {
	padding: 10px !important;
	background: #000 !important;
	box-shadow: none !important;
	margin-bottom: 10px !important;

	:global(.ant-notification-notice-message) {
		margin-bottom: 0 !important;
	}

	:global(.ant-notification-notice-description) {
		color: #fff !important;
	}

	:global(.ant-notification-notice-message) {
		color: #fff !important;
	}

	:global(.ant-notification-notice-icon) {
		top: 50%;
		transform: translate(0, -50%);
	}
}
