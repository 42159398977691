@import "../styles/variables.scss";

.input {
	height: 40px;
	border-color: $grey;
	border-radius: 8px;
	&:hover {
		border-color: $grey;
		box-shadow: none;
	}
	&:focus {
		background-color: $dark;
		color: $white;
		border-color: $dark;
		box-shadow: none;
	}

	&::placeholder {
		color: $grey;
	}
}
