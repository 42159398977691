@import "../styles/variables.scss";

.datePicker {
	display: flex;
	height: 40px;
	font-family: $font;
	input {
		color: $white;
		font-family: $font;
		&::placeholder {
			color: $white !important;
		}
	}

	&:global(.ant-picker-focused) {
		box-shadow: none;
		border-color: $grey;
		background: $dark;
		color: $white;
		input {
			color: $white;
		}
		:global(.ant-picker-suffix) {
			color: $white;
		}
	}

	:global(.ant-picker-suffix) {
		order: -1;
		margin-left: 0;
		margin-right: 10px;
		color: $black;
	}

	:global(.ant-picker-clear) {
		border-radius: 50%;
	}

	:global(.ant-picker-input-active) {
		box-shadow: 0 1px 0 0 $orange;
	}

	:global(.ant-picker-active-bar) {
		display: none;
	}
	:global(.ant-picker-input) {
		width: 96px;
	}
	:global(.ant-picker-range-separator) {
		padding: 0 4px;
	}

	&:hover {
		border-color: $grey;
	}
}

.dropdown {
	font-family: $font;
	:global(.ant-picker-range-arrow) {
		display: none !important;
	}
	:global(.ant-picker-panel-container) {
		background-color: $dark;

		:global(.ant-picker-header) {
			color: $white;
		}

		:global(.ant-picker-cell) {
			color: $white;
		}

		th {
			color: $orange;
		}

		:global {
			.ant-picker-header-super-prev-btn,
			.ant-picker-header-prev-btn,
			.ant-picker-header-super-next-btn,
			.ant-picker-header-next-btn {
				color: $white;
				font-family: $font;
			}
		}

		:global {
			.ant-picker-cell-range-start,
			.ant-picker-cell-range-end {
				:global(.ant-picker-cell-inner) {
					background: $orange;
				}
			}
		}

		:global(.ant-picker-header-view) {
			button {
				font-family: $font;
			}
		}

		// :global {
		// 	.ant-picker-cell-range-hover-start,
		// 	.ant-picker-cell-range-hover-end,
		// 	.ant-picker-cell-range-start,
		// 	.ant-picker-cell-range-end {
		// 		:global(.ant-picker-cell-inner) {
		// 			background: $orange;
		// 		}
		// 	}
		// }

		:global(.ant-picker-cell-inner::after) {
			background: $dark_light !important;
		}

		:global {
			.ant-picker-cell-range-hover-start::before,
			.ant-picker-cell-range-hover-end::before {
				background: $dark_light !important;
				:global(.ant-picker-cell-inner) {
					background: $dark_light !important;
				}
			}
		}

		:global {
			.ant-picker-cell-range-hover-start {
				:global(.ant-picker-cell-inner) {
					background: $dark_light !important;
				}
			}
		}

		:global {
			.ant-picker-cell-range-hover-start::after,
			.ant-picker-cell-range-hover-end::after,
			.ant-picker-cell-range-hover::after {
				display: none;
			}
		}

		:global {
			.ant-picker-cell-range-hover::before {
				background: $violet !important;
			}
		}

		:global(.ant-picker-cell-today) {
			:global(.ant-picker-cell-inner) {
				background: $violet;
				&::before {
					display: none;
				}
			}
		}

		:global {
			.ant-picker-cell-range-start::before,
			.ant-picker-cell-range-end::before {
				background: $dark_light !important;
			}
		}

		:global(.ant-picker-cell-in-range::before) {
			background: $dark_light;
		}

		:global {
			.ant-picker-month-btn:hover,
			.ant-picker-year-btn:hover,
			.ant-picker-header-super-prev-btn:hover,
			.ant-picker-header-prev-btn:hover,
			.ant-picker-header-super-next-btn:hover,
			.ant-picker-header-next-btn:hover {
				color: $orange;
			}
		}
	}
}
