@import "../styles/variables.scss";

$lg_menu_size: 1024px;
$md_menu_size: 570px;
$mobile_menu_size: 360px;

.container {
	min-height: 100vh;
	background-color: #d5d5d5;
	// padding: 16px;
	width: 100%;
	display: flex;
	font-family: $font;
}

.main {
	border-radius: 16px;
	margin: 16px;
	height: calc(100vh - 32px);
	background-color: $white;
	flex: 1 1;
	padding: 30px 20px;
	overflow: hidden;
	overflow-y: scroll;
}

.sidebar {
	@media (max-width: $lg_menu_size) {
		position: fixed;
		right: 0;
		top: 0;
		z-index: 999;
		height: calc(100vh - 32px);
		width: 300px;
		.sidebarContainer {
			border-left: 1px solid #d5d5d5;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.sidebarContainer {
		background-color: $white;
		margin: 16px 16px 16px 0;
		padding: 30px 20px;
		border-radius: 16px;
		height: calc(100vh - 32px);
		.sidebarHeader {
			display: none;
		}
		@media (max-width: $md_menu_size) {
			margin: 16px;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
		}

		@media (max-width: $lg_menu_size) {
			.sidebarHeader {
				display: flex;
			}
		}
	}
}
