.page {
  margin-right: 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
  a {
    font-size: 18px;
    color: rgb(100, 100, 100);
  }
}

.next {
  @extend .control;
  margin-left: 18px;
}

.prev {
  margin-right: 18px;
  @extend .control;
}

.active a {
  color: #1890ff;
  font-weight: bold;
}

.control {
  background: #ffffff;
  box-shadow: 2px 2px 3px #dadada;
  border-radius: 6px;

  &,
  a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    width: 15px;
  }
}

.disabled {
  &:hover {
    cursor: default;
  }

  background: rgb(173, 173, 173);
  box-shadow: none;
}

.container {
  flex: 3;
  padding: 0;
  display: inline-flex;
  align-items: center;
  list-style-type: none;
  justify-content: center;
}

.paginationContainer {
  display: flex;
  margin-top: 25px;
  width: fit-content;
}

.selectContainer {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.select {
  width: 40px;
}

.showPerPage {
  flex: 1;
  display: flex;
  align-items: center;

  span {
    color: rgba(72, 72, 72, 0.7);
    margin-right: 8px;
  }
}
