@import "../../styles/variables.scss";
.container {
	display: flex;
	min-height: 100%;

	font-family: $font;
	flex-direction: column;
	justify-content: flex-end;
}

.header {
	padding: 18px 24px;
	font-size: 14px;

	position: fixed;
	top: 0;
	.title {
		color: #000000;
		opacity: 0.4;
		letter-spacing: 0.02em;
	}
	.count {
		font-size: 50px;
	}
}

.noData {
	display: flex;
	color: rgba($dark, 0.8);
	align-items: center;
	margin-bottom: 50%;
	justify-content: center;
}

.widgetLoading {
	:global(.ant-spin-container) {
		height: 100%;
	}
}
