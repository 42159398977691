.title {
  margin-bottom: 0 !important;
}
.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.tableButtons {
  display: flex;
  justify-content: center;
}
.formContainer {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.row {
  cursor: pointer;
}

.icons {
  font-size: 25px;

  &:first-child {
    margin-right: 20px;
  }
}
