@import 'sass/mixins';
@import 'sass/colors';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoutContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .logoutUserName {
    color: white;
    font-weight: 16px;
    margin: 0 15px 0 0;
  }
}

.notiButton {
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  text-align: center;
  height: 25px;
  transition: 0.3s all;

  span {
    margin-top: -2px;
  }

  &:hover {
    background-color: #1890ff;
    color: white;
    transition: 0.3s all;
  }
}

.logoutIcon {
  font-size: 24px;
  color: white;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
}
.userIcon {
  font-size: 24px;
  color: white;
  margin-right: 10px;
  transition: all 0.2s;
  &:hover {
    transition: all 0.2s;
    cursor: pointer;
    color: $blue;
  }
}

.logo {
  color: white;
}

.arrowIcon {
  font-size: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 8px;
  }
}

.menuItem {
  display: flex;
  align-items: center;
}

.spinnerContainer {
  margin: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  padding: 30px 50px;
  text-align: center;
  background: rgba(150, 139, 139, 0.397);
  border-radius: 4px;
}

.spinner {
  margin-top: 30vh;
  span {
    font-size: 40px;
  }
}

.sider {
  :global(.ant-menu-item) {
    display: flex;
    align-items: center;
  }

  :global(.ant-menu-submenu) {
    :global(.ant-menu-submenu-title) {
      display: flex;
      align-items: center;
    }
  }

  :global(.anticon) {
    line-height: 0 !important;
  }
  :global(.ant-layout-sider-children) {
    height: auto;
    position: sticky;
    top: 0;
  }
}
