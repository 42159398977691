.toolbar {
  margin: 20px 0;
}

.formItem {
  margin-right: 20px;
}

.rangepicker {
  margin-top: 10px;
}

.datapicker {
  width: 100% !important;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.mb {
  min-width: 200px;
}
