@import "shared/components/styles/colours.scss";

.dashboardSettingsModal {
	:global(.ant-modal-content) {
		padding: 0;
		overflow: hidden;
	}

	:global(.ant-tabs-nav) {
		padding: 10px 30px;
		background-color: $black;

		:global(.ant-tabs-tab) {
			color: $white;
		}

		:global(.ant-tabs-tab-active) {
			:global(.ant-tabs-tab-btn) {
				color: $orange;
			}
		}

		:global(.ant-tabs-ink-bar) {
			display: none;
		}
	}

	:global(.ant-modal-close) {
		:global(.anticon-close) {
			svg {
				color: $white;
			}
		}
	}
}

.modalContainer {
	margin: 15px 30px;
}
